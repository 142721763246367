import React from 'react';
import DownloadButton from './DownloadButton';

const GeneratedImages = ({ images }) => (
  <div className="mt-8 space-y-6">
    <h2 className="text-2xl font-bold text-center">Generated Covers</h2>
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
      {images.map((imageUrl, index) => (
        <div key={index} className="relative overflow-hidden rounded-lg bg-white/10 p-4">
          <img
            src={imageUrl}
            alt={`Generated Cover ${index + 1}`}
            className="w-full rounded-lg shadow-lg"
          />
          <DownloadButton imageUrl={imageUrl} index={index} />
        </div>
      ))}
    </div>
  </div>
);

export default GeneratedImages; 