import React from 'react';

function MaterialSelector({ onMaterialChange, selectedMaterial }) {
  const materials = [
    { id: 'wood', name: 'Wood', description: 'Classic and warm, available in Oak, Pine, Cedar, Douglas Fir.' },
    { id: 'stone', name: 'Stone', description: 'Sturdy and timeless, often used in foundations.' },
    { id: 'steel', name: 'Steel', description: 'Modern and strong, used for structural support.' },
    { id: 'concrete', name: 'Concrete', description: 'Durable and robust, often used in bases.' },
    { id: 'brick', name: 'Brick', description: 'Rarely used, adds a decorative touch.' },
  ];

  return (
    <div className="space-y-4">
      <label className="mb-2 block text-sm font-medium text-white">
        Select Bridge Cover Material
      </label>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        {materials.map((material) => (
          <div
            key={material.id}
            onClick={() => onMaterialChange(material.id)}
            className={`cursor-pointer rounded-lg border p-4 transition-colors duration-200 ${
              selectedMaterial === material.id
                ? 'border-brown-700 border-2 bg-white/30 text-brown-700'
                : 'border-white/20 bg-white/10 hover:bg-opacity-70 hover:shadow-lg'
            }`}
            style={{
              backgroundImage: selectedMaterial === material.id ? `url('/path/to/${material.id}-texture.jpg')` : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <h3 className="mb-1 text-lg font-medium text-white">{material.name}</h3>
            <p className="text-sm text-white/60">{material.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MaterialSelector; 