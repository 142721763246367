import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CreditTracker from './CreditTracker';
import { useCredits } from '../hooks/useCredits';
import AtawalkLogo from '../assets/Atawalk-logo-dark-theme.png';

const Navbar = () => {
  const location = useLocation();
  const { credits, refreshCredits } = useCredits();

  useEffect(() => {
    refreshCredits();
  }, [refreshCredits]);

  // Add click handler for the entire app
  useEffect(() => {
    const handleClick = () => {
      refreshCredits();
    };

    document.addEventListener('click', handleClick);
    
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [refreshCredits]);

  return (
    <nav className="fixed left-1/2 top-4 z-50 w-11/12 max-w-6xl -translate-x-1/2 transform">
      <div className="relative overflow-hidden rounded-lg bg-background/90 shadow-2xl backdrop-blur-sm border border-primary transition-all duration-300 group">
        {/* Bridge Roof - Triangular shape with enhanced gradient */}
        <div className="absolute left-0 right-0 top-0 h-0 bg-secondary transition-all duration-300 group-hover:h-2"></div>

        {/* Main Navigation Content */}
        <div className="relative px-6 py-4">
          <div className="flex items-center justify-between">
            {/* Logo Section */}
            <div className="flex-shrink-0">
              <Link to="/" className="flex items-center">
                <img 
                  src={AtawalkLogo} 
                  alt="Atawalk Logo" 
                  className="h-8 w-auto transform transition-transform duration-500 group-hover:translate-x-[10px]"
                />
              </Link>
            </div>

            {/* Navigation Links */}
            <div className="flex items-center space-x-8">
              <div className="flex space-x-8">
                <Link
                  to="/"
                  className={`rounded-md px-3 py-2 text-sm font-medium transition-colors ${
                    location.pathname === '/'
                      ? 'bg-accent text-background'
                      : 'text-surface hover:bg-accent/20 hover:text-surface'
                  }`}
                >
                  Creative Generation
                </Link>
                <Link
                  to="/realistic"
                  className={`rounded-md px-3 py-2 text-sm font-medium transition-colors ${
                    location.pathname === '/realistic'
                      ? 'bg-accent text-background'
                      : 'text-surface hover:bg-accent/20 hover:text-surface'
                  }`}
                >
                  Realistic Generation
                </Link>
              </div>
              {credits !== null && <CreditTracker credits={credits} />}
            </div>
          </div>
        </div>

        {/* Bridge Support Beams and Suspension */}
        <div className="absolute bottom-0 left-0 flex w-full justify-between px-4">
          <div className="h-0 w-4 bg-primary transition-all duration-500 ease-in-out group-hover:h-8 group-hover:w-1"></div>
          <div className="h-0 w-4 bg-primary transition-all duration-500 ease-in-out group-hover:h-8 group-hover:w-1"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
