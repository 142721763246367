import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageUploader from '../components/ImageUploader';
import LeonardoParams from '../components/LeonardoParams';
import { useApi } from '../hooks/useApi';
import { useCredits } from '../hooks/useCredits';
import { cleanPrompt } from '../utils/helpers';
import atawalkBanner from '../assets/atawalk-banner.jpg';
import DownloadButton from '../components/DownloadButton';

function LeonardoPage() {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [params, setParams] = useState({
    prompt: '',
    alchemy: true,
    contrastRatio: 0.5,
    guidance_scale: 10,
    height: 512,
    width: 1024,  
    num_images: 1,
    presetStyle: 'PHOTOGRAPHY',
    init_strength: 0.2,
    num_inference_steps: 55,
    photoRealStrength: 0.55,
    transparency: 'disabled',
    negative_prompt: '',
    modelId: '5c232a9e-9061-4777-980a-ddc8e65647c6',
  });
  const [error, setError] = useState('');
  const { loading, generateImage } = useApi();
  const { refreshCredits } = useCredits();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const img = new Image();
      img.onload = () => {
        if (file.size > 10 * 1024 * 1024) {
          setError('Image size must be less than 10MB.');
          setUploadedImage(null);
          return;
        }
        setUploadedImage(file);
        setError('');
      };
      img.onerror = () => {
        setError('Invalid image file.');
        setUploadedImage(null);
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleGenerateImage = async () => {
    if (!uploadedImage) {
      setError('Please upload a valid image.');
      return;
    }

    try {
      const cleanedParams = {
        ...params,
        prompt: cleanPrompt(params.prompt),
        negative_prompt: cleanPrompt(params.negative_prompt)
      };
      
      const result = await generateImage(uploadedImage, cleanedParams);
      setGeneratedImages(result.generatedImages);
      refreshCredits();
      setError('');
    } catch (error) {
      console.error('Error details:', {
        message: error.message,
        stack: error.stack,
        name: error.name
      });
      setError(error.message);
    }
  };

  return (
    <div className="mx-auto max-w-4xl text-white">
      <div className="mb-8">
        <img 
          src={atawalkBanner} 
          alt="Atawalk Banner" 
          className="w-full rounded-lg shadow-lg mb-8"
        />
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight">Creative Covered Bridge Generator</h1>
          <p className="mt-2 text-xl text-white/60">Transform your bridge with AI-powered creativity</p>
        </div>
      </div>

      {error && (
        <div className="mb-4 p-2 bg-red-100 text-red-700 border border-red-400 rounded">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        <div className="space-y-6">
          <ImageUploader onFileChange={handleFileChange} />
          
          <div className="space-y-4">
            <div>
              <label className="mb-2 block text-sm font-medium">Creative Prompt</label>
              <textarea
                name="prompt"
                value={params.prompt}
                onChange={(e) => setParams(prev => ({ ...prev, prompt: e.target.value }))}
                placeholder="Describe how you want your covered bridge to look..."
                className="w-full rounded-lg border border-white/20 bg-white/10 p-4 text-white placeholder:text-white/40 focus:border-white/30 focus:ring-white/20"
                rows={4}
              />
              <Link to="/prompt-guide" className="text-blue-400 hover:underline mt-2 block">
                Need help with prompts? Click here for the guide.
              </Link>
            </div>

            <div className="mt-4">
              <LeonardoParams params={params} setParams={setParams} />
            </div>

            <button
              onClick={handleGenerateImage}
              disabled={!uploadedImage || !params.prompt.trim() || loading}
              className="w-full rounded-lg bg-gradient-to-r from-purple-500 to-pink-500 px-5 py-3 text-center text-sm font-medium text-white hover:from-purple-600 hover:to-pink-600 focus:outline-none focus:ring-4 focus:ring-purple-300 disabled:cursor-not-allowed disabled:opacity-50"
            >
              {loading ? 'Generating...' : 'Generate Images'}
            </button>
            {loading && (
              <div className="flex justify-center items-center mt-4">
                <div className="loader"></div>
              </div>
            )}
          </div>
        </div>

        <div className="space-y-6">
          {uploadedImage && (
            <div className="overflow-hidden rounded-lg bg-white/10 p-4">
              <img
                src={URL.createObjectURL(uploadedImage)}
                alt="Uploaded Bridge"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
          )}
          
          {generatedImages.length > 0 && (
            <div className="space-y-4">
              <h2 className="text-2xl font-bold">Generated Variations</h2>
              <div className="grid grid-cols-2 gap-4">
                {generatedImages.map((imageUrl, index) => (
                  <div key={index} className="relative overflow-hidden rounded-lg bg-white/10">
                    <img
                      src={imageUrl}
                      alt={`Generated ${index + 1}`}
                      className="w-full rounded-lg shadow-lg"
                    />
                    <DownloadButton imageUrl={imageUrl} index={index} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeonardoPage;
